import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.css';

export const Checkbox = ({ label, id, value, onChange }) => {
  return (
    <label className="checkboxLabel">

      <span>
        <input type="checkbox" checked={value} onChange={onChange} value={id} />
        <span></span>
      </span>
      {label}
    </label>
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string.isRequired,
   onChange: PropTypes.func,
};

export default Checkbox;