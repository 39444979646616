import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';
import './answer.css';

const numberToLetters = ['', 'A', 'B', 'C', 'D'];

const Answer = ({ answer_id, label_id, label, isCheckbox, handleSubmit, onChange, submitting}) => {


let answerClass = "answer"+numberToLetters[label_id];
let answerDisabled = submitting ? " answerDisabled" : "";

if(isCheckbox) {
  return(
    <section className={`answer ${answerClass}`}>
      <Checkbox onChange={onChange} id={answer_id} label={label} />
    </section>
  )
}
  return(
    <section className={`answer ${answerClass}${answerDisabled}`} onClick={() =>{ if(submitting) {return null}else{ return handleSubmit(answer_id)} }}  tabIndex={answer_id} >
      <h3>{numberToLetters[label_id]}</h3>
      <p>{label}</p>
    </section>
  )
}

Answer.propTypes = {
    answer_id: PropTypes.string,
    label_id: PropTypes.string,
    label: PropTypes.string,
    correct: PropTypes.bool,
    isCheckbox: PropTypes.bool,
    onSubmitAnswer: PropTypes.func,
    onChange: PropTypes.func
}
export default Answer;