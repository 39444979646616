import React from 'react';
import './loading.css';



const Loading = () => {
//140px;
  return (
  <div className="loading" >
    <img src="/images/avinor_hms-loader-final-final-final.gif"  alt="Laster inn"/>

  </div>
  )
}

export default Loading;