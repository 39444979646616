import React, { useState } from "react";
import PropTypes from "prop-types";
import { calculateScore } from "../../helpers";
import Image from "../Image";
import Button from "../Button";
import "./answerfeedback.css";

const AnswerFeedback = ({
  question,
  totalquestions,
  msg,
  status,
  points,
  totalpoints,
  onSubmit,
  section,
}) => {
  const heading = status ? "Riktig" : "Feil";
  const img = status
    ? "/images/thumbs_up-icon.svg"
    : "/images/thumbs_down-icon.svg";
  const [buttonDisabled, setButtonDisabled] = useState(false);
  function handleSubmit() {
    setButtonDisabled(true);
    onSubmit();
  }
  let sectionPoints = 0;

  sectionPoints = calculateScore(points, section);
  return (
    <div className="feedback-container">
      <div className="feedback-text">
        <div className="thumbimg">
          <Image imgsrc={img} cls="thumbicon" />
        </div>
        <h2>{heading}</h2>
        <p>{msg}</p>

        <div className="feedback-points">
          <div className="brdr"></div>
          {status && <h4 className="addpoints">+ 10 POENG</h4>}
          <h4>
            {sectionPoints}/{totalpoints} POENG
          </h4>
        </div>
      </div>
      <Button
        label={question >= totalquestions ? "Avslutt" : "Neste oppgave"}
        buttonDisabled={buttonDisabled}
        className="bottomPos"
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

AnswerFeedback.propTypes = {
  msg: PropTypes.string,
  status: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default AnswerFeedback;
