export function calculateScore(points, section) {
  const mainpointsArr = points.split("|");
  let sectionPoints = 0;
  for (let i = 0; i < mainpointsArr.length; i++) {
    if (section === 1 && mainpointsArr[i] < 16) {
      sectionPoints = sectionPoints + 10;
    } else if (
      section === 2 &&
      mainpointsArr[i] > 15 &&
      mainpointsArr[i] < 31
    ) {
      sectionPoints = sectionPoints + 10;
    } else if (
      section === 3 &&
      mainpointsArr[i] > 30 &&
      mainpointsArr[i] < 46
    ) {
      sectionPoints = sectionPoints + 10;
    } else if (
      section === 4 &&
      mainpointsArr[i] > 46 &&
      mainpointsArr[i] < 60
    ) {
      sectionPoints = sectionPoints + 10;
    }
  }
  return sectionPoints;
}

export function calculateTotalScore(points) {
  const mainpointsArr = points.split("|");
  let sectionPoints = 0;
  for (let i = 0; i < mainpointsArr.length; i++) {
    sectionPoints = sectionPoints + 10;
  }
  return sectionPoints;
}
