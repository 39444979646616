import React from "react";
import PropTypes from "prop-types";
import { calculateScore } from "../../helpers";
import "./stats.css";

const Stats = ({ title, totalPoints, points, sectionNr }) => {
  let sectionPoints = 0;

  sectionPoints = calculateScore(points, sectionNr);
  console.log(sectionPoints);
  return (
    <div className="stats">
      {title && <h1>{title}</h1>}
      <div className="finalscore">
        <h3>DIN SCORE</h3>
        <div className="largeText color-primar">
          {sectionPoints ? sectionPoints : 0}/{totalPoints}
        </div>
      </div>
    </div>
  );
};

Stats.propTypes = {
  title: PropTypes.string,
  totalPoints: PropTypes.number,
  points: PropTypes.string,
};
export default Stats;
