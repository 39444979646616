import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Article from "../../components/Article/Article";
import parse from "html-react-parser";
import Button from "../../components/Button/";
import axios from "axios";
import * as Constants from "../../shared/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Loading from "../../components/Loading";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../context/UserContext";
import "./mainscreen.css";

export function MainScreen() {
  const userCntx = React.useContext(UserContext);
  let query = new URLSearchParams(useLocation().search);
  const tparam = "demo";
  const { height, width } = useWindowDimensions();
  const [blockheight, setBlockHeight] = useState(-1);
  const [updateHeight, setupdateHeight] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [section, setSection] = useState(null);
  const [cookieQuestion, setCookieQuestion] = useState(userCntx.question);
  const [loading, setLoading] = useState(true);
  const [showArticle, setArticle] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["question"]);

  const ref = useRef(null);
  const titleRef = useRef(null);

  const answersRef = useRef(null);

  const [token, setToken] = useState(null);
  const question = useRef(null);

  let divStyle =
    blockheight > -1 ? { height: blockheight + "px" } : { height: "auto" };

  function openArticle() {
    var root = document.getElementsByTagName("html")[0];
    root.setAttribute("class", "scrollBody");
    setArticle(true);
  }
  function closeArticle() {
    var root = document.getElementsByTagName("html")[0];
    root.setAttribute("class", "");
    setArticle(false);
  }

  useEffect(() => {
    const checkToken = async () => {
      const nextQuestion = cookies.question
        ? parseInt(cookies.question, 10)
        : 1;

      const result = await axios.get(`${Constants.API_URL}/us/${nextQuestion}`);

      let results = result.data;

      if (results.u) {
        if (results.u.task_id) {
          if (
            parseInt(results.u.task_id, 10) < parseInt(results.s.lasttask, 10)
          ) {
            question.current = parseInt(results.u.task_id, 10) + 0;
          } else {
            question.current = -1;
          }
        } else {
          if (results.s.firsttask === "-1") {
            question.current = -1;
          } else {
            question.current = 1;
          }
        }
        setCookieQuestion(results.u.task_id);
        setToken(tparam);
        setTitle(results.s.title);
        if (parseInt(userCntx.question, 10) > 59) {
          setDescription(
            "Du har fullført spillet, bra jobba! Vi håper du nå føler deg tryggere på å gjenkjenne når det er riktig å bry seg og si ifra."
          );
        } else {
          setDescription(results.s.description);
        }
        setSection(results.s.section);
      }
      if (results.u && tparam !== userCntx.token) {
        userCntx.updateToken(tparam);
      }
      setLoading(false);
      setupdateHeight(true);
    };

    checkToken();
  }, [tparam]);

  useLayoutEffect(() => {
    if (
      !ref ||
      !answersRef ||
      !titleRef ||
      !ref.current ||
      ref.current.clientHeight === 0
    )
      return;

    let titleMargTop = parseInt(
      window.getComputedStyle(titleRef.current).marginTop,
      10
    );
    let titleMargBottom = parseInt(
      window.getComputedStyle(titleRef.current).marginBottom,
      10
    );

    let answersMargBottom = answersRef.current
      ? parseInt(window.getComputedStyle(answersRef.current).marginBottom, 10)
      : 0;
    let answersText = answersRef.current ? answersRef.current.offsetHeight : 0;
    var bheight =
      height -
      answersMargBottom -
      answersText -
      ref.current.offsetHeight -
      titleRef.current.offsetHeight -
      titleMargBottom -
      titleMargTop -
      8;

    if (width < 500) {
      setBlockHeight(bheight);
    } else {
      setBlockHeight(-1);
    }
  }, [updateHeight, height, width]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container maincontainer">
      <div className="imgheader">
        {section && (
          <picture>
            <source
              srcSet={`/images/00_Kap-${section}-intro_desktop.jpg`}
              media="(min-width: 601px)"
            />
            <source
              srcSet={`/images/00_Kap-${section}-intro_mobil.jpg`}
              media="(max-width: 600px)"
            />
            <img
              src={`/images/00_Kap-${section}-intro_desktop.jpg`}
              alt="Forsidebildet"
            />
          </picture>
        )}
      </div>

      <div className="mainpage">
        <h1 ref={titleRef}>{title}</h1>
        <p className="intro" style={divStyle}>
          {parse(description)}

          <br />
          {cookies.name}
          {/*<span className="bluelink" onClick={() => openArticle()}>
            Se konkurranseregler
        </span>*/}
        </p>
        {token && (
          <div className="answers" ref={answersRef}>
            {question.current > 0 && (
              <Link to={`/t/${question.current}`}>
                <Button
                  label={
                    question.current === 1 ||
                    question.current === 16 ||
                    question.current === 31 ||
                    question.current === 46
                      ? "Start kapittel " + section
                      : "Fortsett kapittel"
                  }
                  className="margtop"
                  mode="primary"
                ></Button>
              </Link>
            )}
            {parseInt(cookieQuestion, 10) > 59 && section >= 4 && (
              <Link to={`/finish`}>
                <Button
                  label={"Se resultater"}
                  className="margtop"
                  mode="primary"
                ></Button>
              </Link>
            )}
            {question.current === -1 && section < 4 && (
              <Link to={`/`}>
                <Button
                  label={"Neste kapittel kommer snart"}
                  disabled
                  className="margtop"
                  mode="primary"
                ></Button>
              </Link>
            )}
          </div>
        )}
        {showArticle && <Article onSubmit={closeArticle} />}
      </div>
    </div>
  );
}

export default MainScreen;
