import React, { useState, useEffect } from "react";
import UserContext from "../../context/UserContext";
import Loading from "../../components/Loading";
import Stats from "../../components/Stats";
import FinalStats from "../../components/FinalStats";

import Button from "../../components/Button/";
import { Link } from "react-router-dom";

import axios from "axios";
import * as Constants from "../../shared/constants";

import "./statsscreen.css";

export function StatsScreen({ match }) {
  const userCntx = React.useContext(UserContext);

  const token = match.params.token ? match.params.token : userCntx.token;
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [section, setSection] = useState(userCntx.section);

  const [totalScore, setTotalScore] = useState(null);
  const [totalUserScore, setTotalUserScore] = useState(null);
  const [totalTeamScore, setTotalTeamScore] = useState(null);
  const [maxTeamPoints, setMaxTeamPoints] = useState(null);

  const totalPointsPerSection = [0, 150, 150, 150, 140];
  const d = [1];

  function compare(a, b) {
    if (
      parseInt(a.points ? a.points : 0, 10) >
      parseInt(b.points ? b.points : 0, 10)
    ) {
      return -1;
    }
    if (
      parseInt(a.points ? a.points : 0, 10) <
      parseInt(b.points ? b.points : 0, 10)
    ) {
      return 1;
    }
    return 0;
  }

  function updateTeamData(teamdata, nrUserFinishedSection, usrdata) {
    let sections = {};

    sections[0] = [];
    sections[1] = [];
    sections[2] = [];
    sections[3] = [];
    let total = {};

    let totalTeamScore = 0;
    for (var i = 1; i <= nrUserFinishedSection; i++) {
      for (var k = 0; k < teamdata.length; k++) {
        let newpoints = teamdata[k].points ? teamdata[k].points : 0;

        if (!teamdata[k].section || teamdata[k].section === i + "") {
          sections[i - 1][teamdata[k]["navn"]] = parseInt(newpoints, 10);
        } else if (
          teamdata[k].section <= i &&
          !sections[i - 1][teamdata[k]["navn"]]
        ) {
          sections[i - 1][teamdata[k]["navn"]] = 0;
        }
      }
    }

    //final score
    for (var m = 0; m < teamdata.length; m++) {
      let newpoints = teamdata[m].points ? teamdata[m].points : 0;
      if (total[teamdata[m]["navn"]]) {
        total[teamdata[m]["navn"]] =
          total[teamdata[m]["navn"]] + parseInt(newpoints, 10);
      } else {
        total[teamdata[m]["navn"]] = parseInt(newpoints, 10);
      }
      totalTeamScore += parseInt(newpoints, 10);
    }

    setTotalScore(total);
    setTotalUserScore(total[usrdata[0]["navn"]]);
    setTotalTeamScore(totalTeamScore);
    setMaxTeamPoints(Object.keys(total).length * 590);

    sections[0].sort(compare);
    sections[1].sort(compare);
    sections[2].sort(compare);
    sections[3].sort(compare);

    setTeamData(sections);
  }

  useEffect(() => {
    if (!token || !section) return;
    const loadFinalscore = async () => {
      let results = userCntx.points;

      //setUserData(results.u);
      //updateTeamData(results.f, results.u.length, results.u);

      if (!currentSection) {
        setCurrentSection(userCntx.section);
      }

      setLoading(false);

      var root = document.getElementsByTagName("html")[0];
      root.setAttribute("class", "scrollBody");
    };

    loadFinalscore();
    document.title = "Avinor";

    return () => {
      var root = document.getElementsByTagName("html")[0];
      root.setAttribute("class", "");
    };
  }, [token, section]);

  if (loading) {
    return <Loading />;
  }

  let content = [];
  for (let i = 1; i <= section; i++) {
    content.push(
      <Stats
        key={i}
        title={`Kapittel ${i}`}
        sectionNr={i}
        points={userCntx.points}
        totalPoints={totalPointsPerSection[i]}
      />
    );
  }

  const btnTitle = section == "4" ? "Tilbake" : "Neste kapittel ";
  return (
    <div className="container">
      <div className="statspage">
        {section && section == "4" && (
          <FinalStats
            key={"finalstats"}
            title={`RESULTAT TOTALT`}
            sectionNr={4}
            userScore={userCntx.points}
            totalPoints={590}
          />
        )}

        {content}
        <div className="statsButtons">
          <Link to={"/"}>
            <Button
              label={btnTitle}
              className="margtop"
              mode="secondary"
            ></Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StatsScreen;
