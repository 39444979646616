import React, { useState } from "react";

import "./App.css";

import { withRouter } from "react-router-dom";
import Routes from "./routes";
import { useCookies } from "react-cookie";

import UserContext from "./context/UserContext";

function App() {
  const [cookies, setCookie] = useCookies(["token"]);
  const [token, setToken] = useState(cookies.token);
  const [section, setSection] = useState(cookies.section);
  const [question, setQuestion] = useState(cookies.question);
  const [points, setPoints] = useState(cookies.points);

  function addMoreMin() {
    var dt = new Date();
    dt.setMinutes(dt.getMinutes() + 10080);
    return dt;
  }

  function updateToken(t) {
    setToken(t);
    updateCookie("token", t);
  }
  function updateCookie(type, val) {
    setCookie(type, val, { path: "/", expires: addMoreMin() });
  }

  function updateSection(s) {
    setSection(s);
    updateCookie("section", s);
  }

  function updateQuestion(q) {
    setSection(q);
    updateCookie("question", q);
  }

  function updatePoints(q) {
    let pointsArrStr = points ? points : "";
    let pointsArr = pointsArrStr ? pointsArrStr.split("|") : undefined;

    console.log(pointsArrStr, pointsArr, q);
    if (!pointsArr) {
      pointsArr = [];
      pointsArr.push(q);
    } else if (pointsArr && !pointsArr.includes(q)) {
      pointsArr.push(q);
    }
    pointsArrStr = pointsArr.join("|");
    console.log(pointsArrStr);
    setPoints(pointsArrStr);
    updateCookie("points", pointsArrStr);
  }

  return (
    <UserContext.Provider
      value={{
        token: token,
        section: section,
        question: question,
        points: points,
        updateToken: updateToken,
        updateSection: updateSection,
        updateQuestion: updateQuestion,
        updatePoints: updatePoints,
      }}
    >
      <Routes />
    </UserContext.Provider>
  );
}

export default withRouter(App);
