import React from 'react';
import PropTypes from 'prop-types';
import './question.css';

const Question = ({title, description, question}) => {
  return (
    <div className="question">
        <h2>{title}</h2>
        <p>{description}</p>
        {question ? <h1>{question}</h1> : ''}
    </div>
  )
}
Question.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default Question;