import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

const Button = ({className, mode, backgroundColor, size, label,buttonDisabled, ...props }) => {
  const modeCls = !mode ? 'button--primary' : `button--${mode}`;
  let cls = ['button', `button--${size}`, modeCls].join(' ');
  let cln = className ? className : '';
  return (
     <button
     type="button"
     className={`${cls} ${cln}`}
     style={backgroundColor && {backgroundColor: backgroundColor }}
     disabled={buttonDisabled}
     {...props}
   >
     {label}

   </button>
  )
}


Button.propTypes = {
  className: PropTypes.string,
  /**
   * Is this the principal call to action on the page?
   */
   mode: PropTypes.string,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  backgroundColor: null,
  mode: 'primary',
  size: 'medium',
  onClick: undefined,
};

export default Button;