import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Header from "../../components/Header";
import UserContext from "../../context/UserContext";
import * as Constants from "../../shared/constants";

import axios from "axios";
import AnswerFeedback from "../../components/AnswerFeedback";
import TaskImage from "../../components/TaskImage";
import TaskAudio from "../../components/TaskAudio";
import TaskQuiz from "../../components/TaskQuiz";
import TaskMultichoice from "../../components/TaskMultichoice";
import TaskDialog from "../../components/TaskDialog";
import TaskImagemap from "../../components/TaskImagemap";
import Loading from "../../components/Loading";
import { useCookies } from "react-cookie";

import "./gamescreen.css";

export function PureGameScreen({ match, history }) {
  const userCntx = React.useContext(UserContext);

  const questionId = parseInt(match.params.questionId, 10);

  const token = match.params.token ? match.params.token : userCntx.token;

  if (match.params.token && match.params.token !== userCntx.token) {
    userCntx.updateToken(match.params.token);
  }

  const [points, setPoints] = useState(0);
  const [totalpoints, setTotalPoints] = useState(0);

  const [questionTotal, setQuestionTotal] = useState(0);

  const [questionNr, setQuestionNr] = useState(questionId);
  const [taskNr, setTaskNr] = useState(0);

  const [section, setSection] = useState(1);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [displaying, setDisplay] = useState(false);

  const [showFeedback, setShowFeedback] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [feedbeckData, setFeedbackData] = useState("");
  const [answerStatus, setAnswerStatus] = useState(null);
  const [checkAnswer, setCheckAnswer] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["question"]);

  function onSubmitAnswer(e) {
    let answers = e.split(",");

    setCheckAnswer(answers);
  }

  function showResult(correctAnswers, points, lastsectionid) {
    let answerCorrect = correctAnswers > 0 ? true : false;
    let msg = answerCorrect
      ? questionData.question.feedback_correct
      : questionData.question.feedback_wrong;

    let lastsection = lastsectionid ? parseInt(lastsectionid) : 1;

    if (!points) {
      points = 0;
    }
    if (
      lastsection < parseInt(questionData.question.section) &&
      !answerCorrect
    ) {
      points = 0;
    }

    setPoints(points);

    setAnswerStatus(answerCorrect);
    setFeedbackData(msg);
    userCntx.updateQuestion(parseInt(questionData.question.task_id) + 1);

    userCntx.updateSection(questionData.question.section);
    if (answerCorrect) {
      userCntx.updatePoints(questionData.question.task_id);
    }
    setShowFeedback(true);
    setCheckAnswer(null);
  }

  function onSubmitImagemap(r) {
    let answers = r;

    setCheckAnswer(answers);
  }

  function goToNextQuestion() {
    let nextQuestion = questionNr + 1;

    setQuestionNr(nextQuestion);
  }

  function displayContentByType() {
    if (!questionData) return;
    if (displaying) return;

    if (questionData.question.type === "bilde") {
      return <TaskImage task={questionData} onSubmitAnswer={onSubmitAnswer} />;
    } else if (questionData.question.type === "audio") {
      return <TaskAudio task={questionData} onSubmitAnswer={onSubmitAnswer} />;
    } else if (questionData.question.type === "quiz") {
      return <TaskQuiz task={questionData} onSubmitAnswer={onSubmitAnswer} />;
    } else if (questionData.question.type === "multichoice") {
      return (
        <TaskMultichoice task={questionData} onSubmitAnswer={onSubmitAnswer} />
      );
    } else if (questionData.question.type === "dialog") {
      return <TaskDialog task={questionData} onSubmitAnswer={onSubmitAnswer} />;
    } else if (questionData.question.type === "imagemap") {
      return (
        <TaskImagemap
          task={questionData}
          onSubmitAnswer={onSubmitImagemap}
          showFeedback={showFeedback}
        />
      );
    }
    setDisplay(true);
    return "Noe gikk galt";
  }

  useEffect(() => {
    if (match.params.questionId !== questionNr && token) {
      setQuestionNr(parseInt(match.params.questionId, 10));
      document.title = "Spørsmål " + match.params.questionId;
    }
  }, [match.params.questionId]);

  useEffect(() => {
    if (!token) return;
    const loadQuestion = async () => {
      const result = await axios.get(
        `${Constants.API_URL}/t/${questionNr}/${token}`
      );

      let results = result.data;
      if (results.error) {
        setError(true);
        setLoading(false);
        return;
      }
      if (!results.question) {
        history.replace(`/`);
        return;
      }
      setQuestionTotal(parseInt(results.questions.total));
      setTotalPoints(parseInt(results.questions.total) * 10);
      setSection(parseInt(results.question.section, 10));

      setTaskNr(parseInt(results.question.sorting, 10));
      userCntx.updateQuestion(results.question.task_id);
      userCntx.updateSection(results.question.section);
      setQuestionData(results);
      setLoading(false);
      showFeedback && setShowFeedback(false);

      if (parseInt(results.question.task_id, 10) !== parseInt(questionId, 10)) {
        history.replace(`/t/${results.question.task_id}`);
      }
    };

    if (taskNr >= questionTotal && taskNr !== 0) {
      history.replace(`/finish`);
    } else {
      loadQuestion();
    }
  }, [questionNr, token]);

  useEffect(() => {
    if (!checkAnswer) return;
    const sendAnswer = async () => {
      let obj = {
        uid: questionData.u.user_id,
        t: token,
        tid: questionNr,
        answers: checkAnswer,
        qtype: questionData.question.type,
        section: questionData.question.section,
        orgnr: questionData.u.orgnr,
      };

      const result = await axios.post(
        `${Constants.API_URL}/useranswer/${questionNr}/${token}`,
        {
          ...obj,
        }
      );

      let results = result.data;
      if (results.points) {
        showResult(results.a, results.points, questionData.u.section);
      } else {
        showResult(results.a, questionData.u.points, questionData.u.section);
      }
    };

    sendAnswer();
  }, [checkAnswer, questionNr, token]);

  if (!token) {
    document.title = "Avinor ";
    history.push("/");
    return "";
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="container">Feil!</div>;
  }
  if (!loading && !error) {
    return (
      <div className="container">
        <Header progress={taskNr} total={questionTotal} section={section} />
        {!loading && questionData && displayContentByType()}
        {showFeedback && (
          <AnswerFeedback
            section={section}
            question={taskNr}
            totalquestions={questionTotal}
            msg={feedbeckData}
            status={answerStatus}
            points={userCntx.points}
            totalpoints={totalpoints}
            onSubmit={goToNextQuestion}
          />
        )}
      </div>
    );
  }
}

PureGameScreen.propTypes = {
  /** The error message */
  error: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
};

PureGameScreen.defaultProps = {
  error: null,
};

export default PureGameScreen;
