import React, { useEffect } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./video.css";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);

  const { options, onReady, playerRef, isAudio, audioPause } = props;
  const { height, width } = useWindowDimensions();
  let imgRation = 0.833333333;

  let imgPadding = width > 375 ? 16 : 8;
  let btnPadd = height > 650 ? 10 : 30;
  let maxWidth = width > 820 ? 820 : width;

  let offHeight = width > 768 ? 396 : 226;
  if (isAudio) {
    imgRation = 1;
    btnPadd = 0;
  }
  let imgWidth = maxWidth - imgPadding;
  imgWidth = imgWidth >= 820 ? 820 : imgWidth;

  let imgHeight = imgWidth / imgRation - btnPadd;
  imgWidth = imgHeight * imgRation;

  if (width / (height - 222) > imgRation) {
    imgHeight = height - offHeight;
    if (!isAudio) {
      imgWidth = imgHeight * imgRation;
    } else {
      imgWidth = imgHeight;
    }
  }
  imgHeight = imgHeight > 984 ? 984 : imgHeight;
  imgWidth = imgWidth > 820 ? 820 : imgWidth;
  const divStyle = {
    height: imgHeight + "px",
    width: imgWidth + "px",
    margin: "0 auto",
    backgroundColor: "#fff",
  };

  // This seperate functional component fixes the removal of the videoelement
  // from the DOM when calling the dispose() method on a player
  const VideoHtml = (props) => (
    <div data-vjs-player style={divStyle}>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
  useEffect(() => {
    if (audioPause) {
      playerRef.current.pause();
    }
  }, [audioPause]);
  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      let player;

      if (videoElement) {
        player = videojs(videoElement, options, () => {
          onReady && onReady(player);
        });
      }
    } else {
      // you can update player here [update player through props]
    }
  }, [height, width, playerRef.current]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);
  return (
    <div data-vjs-player style={divStyle}>
      <video
        ref={videoRef}
        playsInline
        controls={false}
        className="video-js vjs-big-play-centered"
      />
    </div>
  );
};
export default VideoJS;
