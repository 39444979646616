import React from 'react';
import PropTypes from 'prop-types';
import './grid.css';

const GridCell = ({row, cell, selRow, selCell, onClickEvent}) => {
  const isActive = (row === selRow && cell === selCell) ? true : false;
  const cls = ["grid-cell"];
  if(isActive){
    cls.push("grid-cell-selected")
  }
  return (
    <div className={cls.join(" ")} key={row+'-'+cell} onClick={(e) => onClickEvent(cell,row, e)}></div>
  )
}


GridCell.propTypes = {
  rowNr: PropTypes.number,
  cellNr: PropTypes.number,
  onClickEvent: PropTypes.func,
  selRow: PropTypes.number,
  selCell: PropTypes.number,


}

export default GridCell;
