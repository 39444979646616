import React from "react";
import { Switch, Route } from "react-router-dom";
import MainScreen from "./views/MainScreen";
import StatsScreen from "./views/StatsScreen";

import GameScreen from "./views/GameScreen";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={MainScreen} />
      <Route exact path="/stats" component={StatsScreen} />

      <Route exact path="/t/:questionId" component={GameScreen} />
      <Route exact path="/finish" component={StatsScreen} />
    </Switch>
  );
}
