import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import './image.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Image = ({imgsrc, isaudio, grid, isfullscreen, ismap, isdialog, updateSelectedRowCell, selectedRow, selectedCell, responsive}) => {
  const fullscreenCls= isfullscreen ? " full" : "";
  const imgmapCls= ismap||isdialog ? " imgmap" : "";
  const imgBgCls = ismap||isdialog ? " imgbg" : "";

  const { height, width } = useWindowDimensions();
  let imgRation = 0.833333333;
  let imgPadding = width >375 ? 16 : 8;
  let btnPadd = height >650 ? 10 : 30;
  let imgWidth = width-imgPadding;
  let hoffset = width>768 ? 396 : 276;
  imgWidth = imgWidth >= 820 ? 820 : imgWidth;

  let imgHeight = imgWidth / imgRation - btnPadd;
//  imgWidth = imgHeight * imgRation;
  if(ismap) {
    hoffset = 256;
  }
  if(width>height - hoffset ){
    imgHeight = height - hoffset;
    imgWidth = imgHeight * imgRation;
  }
  imgHeight = imgHeight >= 984 ? 984 : imgHeight;
  imgWidth = imgWidth >= 820 ? 820 : imgWidth;

  if(!ismap && !isdialog){
    let iHeight = height - hoffset;
    let iWidth = width-imgPadding;
    imgWidth = width-imgPadding;
    imgHeight = imgWidth;

    if(iHeight>iWidth ){

      imgWidth = width-imgPadding;
      imgHeight = imgWidth;
    }else if(iWidth/iHeight > 1){
      imgHeight = height - hoffset;
      imgWidth = imgHeight;
    }
    imgHeight = imgHeight > 1000 ? 1000 : imgHeight;
    imgWidth = imgWidth > 820 ? 820 : imgWidth;
  }
  let divStyle=  ismap||isdialog ? {backgroundImage: 'url(' + imgsrc + ')', height: imgHeight+'px', width: imgWidth+'px'}: { height: imgHeight+'px', width: imgWidth+'px', margin: '0 auto'};
  if(!responsive){
    divStyle = {};
  }
 function generateMap() {
    if(ismap){

     return (<>
      <div className={`imgcontainer${imgBgCls}`} style={divStyle}></div>
      <Grid gridheight={imgHeight} gridwidth={imgWidth} mainCls="imggrid" grid={grid} selectedRow={selectedRow} selectedCell={selectedCell} updateSelectedRowCell={updateSelectedRowCell}></Grid>
      </>);
    }else if(isdialog){
      return ( <div className={`imgcontainer${imgBgCls}`} style={divStyle}></div>);
    }else{

      return ( <div className={`imgcontainer${imgBgCls}`} style={divStyle}>
        <img src={imgsrc} alt="map" className="imground"/>
        { isaudio && <div className="image-play"><img src={"/images/play.png"} alt="Play"/> </div>}
      </div>
      );
    }
   }
  return (
    <div className={`imgholder${fullscreenCls}${imgmapCls}`}>

    { generateMap() }

    </div>
  )
}

Image.propTypes = {
  imgsrc: PropTypes.string,
  isaudio: PropTypes.bool,
  isfullscreen: PropTypes.bool,
  ismap: PropTypes.bool

}
export default Image;