import React from "react";
import PropTypes from "prop-types";
import { calculateTotalScore } from "../../helpers/index";

import "../Stats/stats.css";

const FinalStats = ({ title, totalPoints, userScore }) => {
  var userPoints = calculateTotalScore(userScore);

  return (
    <div className="stats">
      {title && <h1>{title}</h1>}
      <div className="finalscore">
        <h3>DIN SCORE</h3>
        <div className="largeText color-primar">
          {userPoints}/{totalPoints}
        </div>
        <div className="brdr"></div>
      </div>
    </div>
  );
};

FinalStats.propTypes = {
  title: PropTypes.string,
  totalPoints: PropTypes.number,
  userData: PropTypes.object,
  teamData: PropTypes.object,
};
export default FinalStats;
