import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./article.css";

const Article = ({
  question,
  totalquestions,
  msg,
  status,
  points,
  totalpoints,
  onSubmit,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  function handleSubmit() {
    setButtonDisabled(true);
    onSubmit();
  }

  return (
    <div className="article">
      <div className="article-text">
        <h2>Konkurranseregler</h2>

        <p>
          Spillet er tilrettelagt for mobil, men du kan ogs&aring; spille fra
          smartbrett eller PC.
        </p>

        <p>
          Oppgavene kommer i form av quizer, sjekklister, dialogoppgaver, bilde-
          eller lydoppgaver. NB! S&oslash;rg derfor for &aring; se skjermen godt
          og eventuelt koble til &oslash;replugger f&oslash;r du begynner!
        </p>

        <p>Videoer (dialog- og lydoppgaver) kan spilles av flere ganger.</p>

        <p>
          Du inng&aring;r i et lag med mellom 5 og 15 av dine n&aelig;rmeste
          kollegaer p&aring; lufthavnen/ avdelingen.
        </p>

        <p>
          Spillet er delt i 4 niv&aring;er med ca. 15 oppgaver p&aring; hvert
          niv&aring;. Et nytt niv&aring; blir tilgjengelig hver uke ved &aring;
          klikke p&aring; lenken du f&aring;r tilsendt i en e-post. Du f&aring;r
          kun tilgang via din personlige lenke og dermed trenger du ikke &aring;
          logge inn. I e-posten f&aring;r du ogs&aring; informasjon om hvem du
          er p&aring; lag med.
        </p>

        <p>
          Du f&aring;r bare ett fors&oslash;k p&aring; hvert
          sp&oslash;rsm&aring;l. N&aring;r du bekrefter at du har svart, kan du
          ikke g&aring; tilbake. Du kan n&aring;r som helst g&aring; ut av
          spillet. For &aring; g&aring; tilbake til spillet klikker du p&aring;
          nytt p&aring; lenken i e-posten du har mottatt, og da fortsetter du
          der du slapp.
        </p>

        <p>
          Etter hvert niv&aring; f&aring;r du en oppsummering av resultatet for
          b&aring;de deg selv og laget ditt.
        </p>

        <p>
          Etter at du er ferdig med kapittel 4, f&aring;r du se et oppsummert
          resultat for ditt lag.
        </p>

        <p>
          Navnet ditt og e-postadressen din vil slettes fra databasen n&aring;r
          spillperioden er over.
        </p>

        <br />
      </div>
      <Button
        label="Lukk konkurranseregler"
        buttonDisabled={buttonDisabled}
        mode="secondary"
        className="bottomPos"
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

Article.propTypes = {
  msg: PropTypes.string,
  status: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Article;
