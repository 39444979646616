import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Loading from '../Loading';

import Question from '../Question';
import Image from '../Image';
import Answer from '../Answer';

export function PureTaskImage({loading, task, onSubmitAnswer}){
  const [collapsedAnswers, setCollapsedAnswers] = useState(true);
  const [btnLabel, setBtnLabel] = useState('Vis svaralternativer');
  const [submitting, setSubmitting] = useState(false);
  function showAnswers() {
    setCollapsedAnswers(!collapsedAnswers);
    let label = btnLabel === 'Vis svaralternativer' ? 'Skjul svaralternativer' : 'Vis svaralternativer';
    setBtnLabel(label);
  }

  function handleSubmit(e) {
    if(!submitting){
      setSubmitting(true);
      onSubmitAnswer(e);
    }

  }

  useEffect(() => {
    setCollapsedAnswers(true);
    setBtnLabel('Vis svaralternativer');
    setSubmitting(false);
  }, [task.question.title]);

  const events = {
    handleSubmit
  }

  if( loading ) {
    return <Loading />
  }
  let showAnswer = !collapsedAnswers ? ' showanswer' : '';

  return(
   <main>
     <Question title={task.question.title} description={task.question.description}></Question>
     {task.question.image && <Image responsive={true} imgsrc={"/images/"+task.question.image} />}
     <div className={`answers${showAnswer}`}>
     {task.answers.map(answer => (
        <Answer key={answer.answer_id} submitting={submitting} answer_id={answer.answer_id} label_id={answer.sorting} label={answer.title} {...events}></Answer>
     ))}
       <Button onClick={showAnswers} label={btnLabel} mode='tertiar' className="showanswers"></Button>

     </div>
   </main>
  )
}

PureTaskImage.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onSubmitAnswer: PropTypes.func
}
PureTaskImage.defaultProps = {
  loading: false,
};

export default PureTaskImage;
