import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Question from '../Question';
import Image from '../Image';
import Answer from '../Answer';

export function PureTaskQuiz({loading, task, onSubmitAnswer}){

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitting(false);
  }, [task.question.title]);

  function handleSubmit(e) {
    if(!submitting){
      setSubmitting(true);
      onSubmitAnswer(e);
    }
  }

  const events = {
    handleSubmit
  }

  if( loading ) {
    return <div className="loading">Loading...</div>
  }

  return(
   <main>
     <Question title={task.question.heading} question={task.question.title} description={task.question.description}></Question>
     {task.question.image && <Image imgsrc={"/images/"+task.question.image} />}
     <div className="answers quizanswers">
     {task.answers.map(answer => (
        <Answer key={answer.answer_id} submitting={submitting} answer_id={answer.answer_id} label_id={answer.sorting} label={answer.title} {...events}></Answer>
     ))}
     </div>
   </main>
  )
}

PureTaskQuiz.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onSubmitAnswer: PropTypes.func
}
PureTaskQuiz.defaultProps = {
  loading: false,
};

export default PureTaskQuiz;
