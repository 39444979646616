import React from 'react';
import PropTypes from 'prop-types';
import './grid.css';
import GridCell from './GridCell';
import useWindowDimensions from '../../hooks/useWindowDimensions';


const Grid = ({mainCls, grid, gridheight, gridwidth, updateSelectedRowCell, selectedRow, selectedCell}) => {
const { height, width } = useWindowDimensions();
const gridInfo = JSON.parse(grid);
const leftPos = width >= 820 ? (820-gridwidth)/2 : (width-16-gridwidth)/2;
const ratio = (parseInt(gridwidth, 10)/parseInt(gridheight,10));
let leftMrg = ratio < 0.833333333 ?  leftPos : 0;
  leftMrg = 0;

const divStyle = { height: gridheight+'px', width: gridwidth+'px', left: leftMrg+'px'};

function registerClick(cell, row, e){

  updateSelectedRowCell(row, cell)

}
function buildCells(cellNr, rowNr){
  let cellArr=[];
    for(let cell=0; cell<cellNr; cell++){
      cellArr.push(<GridCell key={rowNr+'-'+cell} row={rowNr} cell={cell} selRow={selectedRow} selCell={selectedCell} onClickEvent={(e) => registerClick(cell,rowNr, e)}/>)
    }
    return cellArr;
}

function buildRows(rowNr){
  let rowArr=[];
    for(let row=0; row<rowNr; row++){
      rowArr.push(<div className={`grid-row grid-row-${gridInfo[0].c}`} key={'row-'+row}>{buildCells(gridInfo[0].r, row)}</div>);
    }
    return rowArr;
}
  return (
    <div className={`grid-container ${mainCls}`} style={divStyle}>
      {gridInfo && buildRows(gridInfo[0].c)}

    </div>
  )
}


Grid.propTypes = {
  mainCls: PropTypes.string,
  updateSelectedRowCell: PropTypes.func
}

export default Grid;