import React from 'react';
import './progressbar.css';
import PropTypes from 'prop-types';

const Progressbar = ({value}) => {

  const progessValue = value+"%";
  return (
  <div className="progressbar" >
    <div style={{width: progessValue}}></div>
  </div>
  )
}

Progressbar.propTypes = {
   value: PropTypes.number,
}
export default Progressbar;