import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/'
import Question from '../Question';
import Image from '../Image';
import Answer from '../Answer';
import './taskmultichoice.css';

export function PureTaskMultichoice({loading, task, onSubmitAnswer}){
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  function onChange(e){

    if(e.target.checked) {
      setCheckedBoxes(prevArray => [...prevArray, e.target.value]);
    }else{
      setCheckedBoxes(prevArray => [...prevArray, e.target.value]);
      setCheckedBoxes(checkedBoxes.filter(item => item !== e.target.value));
    }

  }
  const events = {
    onChange
  }
  if( loading ) {
    return <div className="loading">Loading...</div>
  }

  return(
    <>
   <main>
     <Question title={task.question.heading} question={task.question.title} description={task.question.description}></Question>
     {task.question.image && <Image imgsrc={"/images/"+task.question.image} />}
     <div className="answers checkbox-answer">
     {task.answers.map(answer => (
        <Answer key={answer.answer_id} isCheckbox={true} answer_id={answer.answer_id} label_id={answer.sorting} label={answer.title} {...events}></Answer>
     ))}
      <Button label="Bekreft svar" disabled={checkedBoxes.length === 0 ? true : false } onClick={() => onSubmitAnswer(checkedBoxes.join(","))}></Button>
    </div>
   </main>
   </>
  )
}

PureTaskMultichoice.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onSubmitAnswer: PropTypes.func
}
PureTaskMultichoice.defaultProps = {
  loading: false,
};

export default PureTaskMultichoice;
