import React from 'react';
import PropTypes from 'prop-types';
import './header.css';
import Progressbar from '../Progressbar';

const Header = ({user, progress, total}) =>{
  let progressValue = progress ? progress/total * 100 : 0;

  return(
    <header>
       {user ?  user.name : ''}

      <Progressbar value={progressValue} />
      <div className="task">{progress} / {total}</div>
    </header>
  )
}

Header.propTypes = {
  progress: PropTypes.number,
  user: PropTypes.object,
};

Header.defaultProps = {
  progress: 0,
  user: {
    "name": ""
  },
};

export default Header;