import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Question from '../Question';
import Image from '../Image';

import Button from '../Button/'


export function PureTaskImageMap({loading, task, onSubmitAnswer, showFeedback}){
  const [selRowCell, setSelectedRowCell] = useState({}) ;
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCell, setSelectedCell] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
      reset(showFeedback);
  }, [showFeedback]);

  function updateSelectedRowCell(r,c) {
    setSelectedRow(r);
    setSelectedCell(c);
    setSelectedRowCell({r: r, c: c});
  }
  if( loading ) {
    return <div className="loading">Loading...</div>
  }

  function sendAnswer(selRowCell){
    if(!submitting){
      setSubmitting(true);
      onSubmitAnswer(selRowCell);
    }

  }


  function reset(showFeedback){
    if(showFeedback){
      setSelectedRow(null);
      setSelectedCell(null);
      setSelectedRowCell({});
    }
  }

  return(
    <>
   <main className="ci">
     <Question title={task.question.title} description={task.question.description}></Question>
     {task.question.image && <Image responsive={true} imgsrc={"/images/"+task.question.image} ismap={true} grid={task.question.imggrid} selectedRow={selectedRow} selectedCell={selectedCell} updateSelectedRowCell={updateSelectedRowCell}/>}

     <div className="answers">
   <Button label="Bekreft svar" disabled={selectedRow===null ? true : false } onClick={() => sendAnswer(selRowCell)}></Button>
     </div>
   </main>
   </>
  )
}

PureTaskImageMap.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onSubmitAnswer: PropTypes.func
}
PureTaskImageMap.defaultProps = {
  loading: false,
};

export default PureTaskImageMap;
