import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../Button";

import Question from "../Question";

import Answer from "../Answer";
import Video from "../Video";

export function PureTaskAudio({ loading, task, onSubmitAnswer }) {
  const [collapsedAnswers, setCollapsedAnswers] = useState(true);
  const [btnLabel, setBtnLabel] = useState("Vis svaralternativer");
  const [audioPause, setAudioPause] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const playerRef = React.useRef(null);

  useEffect(() => {
    setSubmitting(false);
  }, [task.question.title]);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    poster: "/images/1000x1000-poster.jpg",
    sources: [
      {
        src: "/video/" + task.question.image,
        type: "video/mp4",
      },
    ],
  };

  function showAnswers() {
    setCollapsedAnswers(!collapsedAnswers);
    let label =
      btnLabel === "Vis svaralternativer"
        ? "Skjul svaralternativer"
        : "Vis svaralternativer";
    setBtnLabel(label);
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  function stopAudio() {
    setAudioPause(true);
  }
  function handleSubmit(e) {
    if (!submitting) {
      setSubmitting(true);
      onSubmitAnswer(e);
      stopAudio();
    }
  }

  useEffect(() => {
    setSubmitting(false);
  }, [task.question.title]);
  const events = {
    handleSubmit,
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }
  let showAnswer = !collapsedAnswers ? " showanswer" : "";

  return (
    <main>
      <Question
        title={task.question.title}
        description={task.question.description}
      ></Question>
      {task.question.image && (
        <Video
          isAudio={true}
          audioPause={audioPause}
          playerRef={playerRef}
          options={videoJsOptions}
          onReady={handlePlayerReady}
        />
      )}

      {showAnswer && (
        <div className={`answers${showAnswer}`}>
          {task.answers.map((answer) => (
            <Answer
              key={answer.answer_id}
              answer_id={answer.answer_id}
              label_id={answer.sorting}
              label={answer.title}
              submitting={submitting}
              {...events}
            ></Answer>
          ))}
          <Button
            onClick={showAnswers}
            label={btnLabel}
            mode="tertiar"
            className="showanswers"
          ></Button>
        </div>
      )}

      {!showAnswer && (
        <div className={`answers${showAnswer}`}>
          {task.answers.map((answer) => (
            <Answer
              key={answer.answer_id}
              answer_id={answer.answer_id}
              label_id={answer.sorting}
              label={answer.title}
              submitting={submitting}
              {...events}
            ></Answer>
          ))}
          <Button
            onClick={showAnswers}
            label={btnLabel}
            mode="tertiar"
            className="showanswers"
          ></Button>
        </div>
      )}
    </main>
  );
}

PureTaskAudio.propTypes = {
  loading: PropTypes.bool,
  task: PropTypes.object.isRequired,
  onSubmitAnswer: PropTypes.func,
};
PureTaskAudio.defaultProps = {
  loading: false,
};

export default PureTaskAudio;
